<template>
    <div>
        <base-table :searchForm="searchForm" :searchValue="searchValue" :tableConfig="tableConfig"/>
        <el-dialog title="新建运单" :visible.sync="visible" width="400px" destroy-on-close @close="closeDialog"
                   :center="true">
            <!--:close-on-click-modal="false" :close-on-press-escape="false"-->
            <el-form :model="form" size="mini" label-width="100px" ref="ruleForm" :rules="rules">
                <el-form-item label="业务员：" prop="idUser">
                    <el-select v-model="form.idUser" placeholder="请选择业务员" style="width: 100%" filterable>
                        <el-option v-for="item in userList" :label="item.name+'—'+item.phone" :value="item.id"
                                   :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收货人：" prop="contact">
                    <el-input v-model="form.contact" clearable placeholder="请输入收货人"></el-input>
                </el-form-item>
                <el-form-item label="物流公司：" prop="company">
                    <el-input v-model="form.company" clearable placeholder="请输入物流公司"></el-input>
                </el-form-item>
                <el-form-item label="物流单号：" prop="orderNum">
                    <el-input v-model="form.orderNum" clearable placeholder="请输入物流单号"></el-input>
                </el-form-item>
                <el-form-item label="费用：" prop="cost">
                    <el-input v-model="form.cost" clearable placeholder="请输入费用"></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="note">
                    <el-input type="textarea" :rows="2" v-model="form.note" clearable
                              placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item label="更改状态：" prop="status" v-if="!!form.id">
                    <el-select v-model="form.status" placeholder="请选择运单状态" style="width: 100%" filterable
                               @change="statusChange">
                        <el-option v-for="item in statusList" :label="item.name" :value="item.id"
                                   :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="更改备注：" prop="changeStatusNote" v-if="temStatus!==form.status">
                    <el-input v-model="form.changeStatusNote" clearable placeholder="请输入更改状态备注"></el-input>
                </el-form-item>
                <el-form-item label="物流单：">
                    <el-upload list-type="picture-card"
                               :action="baseApi+'upload'"
                               ref="uUpload"
                               name="file"
                               :data="{path:'createTask',code:form.id}"
                               with-credentials
                               :on-success="onSuccess"
                               :on-error="onError"
                               :on-change="onChange"
                               :file-list="fileList"
                               :auto-upload="!!form.id"
                               :on-progress="onProgress">
                        <i slot="default" class="el-icon-plus upload-plus"></i>
                        <div slot="file" slot-scope="{file}">
                            <el-image :z-index="9999999" fit="contain"
                                      class="el-upload-list__item-thumbnail"
                                      style="width: 50px; height: 50px"
                                      :src="file.url" :preview-src-list="fileList.map(item=>item.url)">
                            </el-image>
                            <span class="delete-photo"
                                  @click="handleRemove(file,'')">
                                    <i class="el-icon-delete"></i>
                            </span>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="回单：" v-if="form&&form.id&&[5,7].indexOf(form.status)>-1">
                    <el-upload list-type="picture-card"
                               :action="baseApi+'upload'"
                               ref="uUpload2"
                               name="file"
                               :data="{path:'backTask',code:form.id}"
                               with-credentials
                               :on-success="onSuccess"
                               :on-error="onError"
                               :on-change="onChange2"
                               :file-list="fileList2"
                               :auto-upload="true"
                               :on-progress="onProgress">
                        <i slot="default" class="el-icon-plus upload-plus"></i>
                        <div slot="file" slot-scope="{file}">
                            <el-image :z-index="9999999" fit="contain"
                                      class="el-upload-list__item-thumbnail"
                                      style="width: 50px; height: 50px"
                                      :src="file.url" :preview-src-list="fileList2.map(item=>item.url)">
                            </el-image>
                            <span class="delete-photo"
                                  @click="handleRemove(file,'2')">
                                    <i class="el-icon-delete"></i>
                            </span>
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog" size="mini">取 消</el-button>
                <el-button type="primary" @click="createForm" size="mini">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="visibleDetails" width="600px" destroy-on-close @close="closeDetails" :center="true"
                   top="50px">
            <el-card :body-style="{ padding: '0px' }">
                <div slot="header" class="clearfix">
                    <span>{{'运单编号：'+taskDetails.code}}</span>
                    <span :style="'float: right; padding: 3px 0;'+(taskDetails.color?('color:'+taskDetails.color):'')"
                          type="text">{{taskDetails.statusName}}</span>
                </div>
                <div class="flex">
                    <div style="flex: 1;text-align: right">{{taskDetails.userName}}</div>
                    <img style="justify-content: center;width:80px;height: 40px;object-fit: fill;margin-left: 10px;margin-right: 10px"
                         :src="arrow" alt="arrow"/>
                    <div style="flex: 1;text-align: left">{{taskDetails.contact}}</div>
                </div>
                <div style="padding: 0 20px 10px 20px;border-bottom: #EBEEF5 1px solid;display: flex">
                    <div style="flex: 1;">
                        <div v-if="isAdmin">
                            <div>物流公司：{{taskDetails.company||'无'}}</div>
                            <div>物流单号：{{taskDetails.orderNum||'无'}}</div>
                            <div>运费：{{taskDetails.cost||'无'}}</div>
                        </div>
                        <div>创建时间：{{taskDetails.createAt||'无'}}</div>
                        <div>最后更新时间：{{taskDetails.updateAt||'无'}}</div>
                        <div>备注：{{taskDetails.note||'无'}}</div>
                    </div>
                    <vue-qr :text="miniApi+taskDetails.code" :size="112" :margin="0" :logoSrc="logo" :logoScale="0.15"
                            :logoCornerRadius="0" :logoMargin="2"/>
                </div>
                <el-timeline style="padding: 10px 20px 0 20px;border-bottom: #EBEEF5 1px solid;">
                    <el-timeline-item
                            v-for="(item, index) in mailList"
                            :key="index" :color="index?'':item.color"
                            :timestamp="item.createAt">
                        <div>{{item.actionName}}</div>
                        <div style="font-size: 12px;padding-top: 2px;color: #606266">
                            {{item.note?('备注：'+item.note):''}}
                        </div>
                    </el-timeline-item>
                </el-timeline>
                <div style="padding: 10px 20px ;display: flex;align-items: center">
                    <div>物流单：</div>
                    <div v-if="!taskDetails.photos||!taskDetails.photos.length" style="flex:1">无</div>
                    <div v-else style="flex:1;display: flex">
                        <!--                        <div style="flex: 1;" v-for="item in taskDetails.photos">-->
                        <!--                            <el-image :z-index="9999999" lazy-->
                        <!--                                      class="el-upload-list__item-thumbnail"-->
                        <!--                                      style="width: 80px; height: 80px"-->
                        <!--                                      :src="item" :preview-src-list="taskDetails.photos">-->
                        <!--                            </el-image>-->
                        <!--                        </div>-->
                        <el-button type="primary" @click="()=>openViewer(taskDetails.photos)"
                                   size="mini">查看({{taskDetails.photos.length}}张)
                        </el-button>

                    </div>
                    <div>回单：</div>
                    <div v-if="!taskDetails.backPhotos||!taskDetails.backPhotos.length" style="flex:1">无</div>
                    <div v-else style="flex:1;display: flex">
                        <!--                        <div style="flex: 1;" v-for="item in taskDetails.backPhotos">-->
                        <!--                            <el-image :z-index="9999999" lazy-->
                        <!--                                      class="el-upload-list__item-thumbnail"-->
                        <!--                                      style="width: 80px; height: 80px"-->
                        <!--                                      :src="item" :preview-src-list="taskDetails.backPhotos">-->
                        <!--                            </el-image>-->
                        <!--                        </div>-->
                        <el-button type="primary" @click="()=>openViewer(taskDetails.backPhotos)"
                                   size="mini">查看({{taskDetails.backPhotos.length}}张)
                        </el-button>
                    </div>
                </div>
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="isAdmin&&taskDetails.buttonName&&taskDetails.status!==5" type="primary"
                           @click="()=>showModal(1)" size="mini">
                    {{taskDetails.buttonName}}
                </el-button>
                <el-upload v-if="isAdmin&&taskDetails.buttonName&&taskDetails.status===5"
                           class="upload-demo"
                           :action="baseApi+'upload'"
                           ref="uUpload2"
                           :show-file-list="false"
                           name="file"
                           :data="{path:'backTask',code:taskDetails.id}"
                           with-credentials
                           :on-success="onSuccess3"
                           :on-error="onError"
                           :auto-upload="!!taskDetails.id"
                           :on-progress="onProgress">
                    <el-button size="mini" type="primary">{{taskDetails.buttonName}}</el-button>
                </el-upload>
                <el-button v-if="isAdmin&&taskDetails.canCancel===1" type="danger"
                           @click="()=>showModal(2)" size="mini">
                    关闭
                </el-button>
            </div>
        </el-dialog>
        <el-image-viewer
                :z-index="9999999" lazy
                v-if="showViewer"
                :on-close="closeViewer"
                :url-list="photoUrls"/>
    </div>
</template>

<script>
    import baseTable from "@/components/baseTable";
    import axiosUtils from "@/utils/axiosUtils";
    import {Message} from "element-ui";
    import arrow from "@/assets/right_arrow.png"
    import logo from "@/assets/logo.png"
    import VueQr from 'vue-qr'
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

    export default {
        name: "taskList",
        components: {
            baseTable, VueQr, ElImageViewer
        },
        data() {
            return {
                arrow,
                logo,
                baseApi: axiosUtils.baseApi,
                miniApi: axiosUtils.miniApi,
                visible: false,
                visibleDetails: false,
                showViewer: false,
                photoUrls: [],
                userList: [],
                fileList: [],
                fileList2: [],
                mailList: [],
                statusList: [],
                taskDetails: {},
                temStatus: '',
                form: {
                    id: '',
                    idUser: undefined,
                    code: '',
                    contact: '',
                    company: '',
                    orderNum: '',
                    cost: '',
                    note: '',
                    changeStatusNote: '',
                    status: 1,
                },
                searchForm: [{
                    type: 'input',
                    label: '运单编号',
                    prop: 'code',
                    width: 120
                }, {
                    type: 'input',
                    label: '业务员',
                    prop: 'userName',
                    width: 120
                }, {
                    type: 'input',
                    label: '收货人',
                    prop: 'contact',
                    width: 120
                }, {
                    type: 'input',
                    label: '物流公司',
                    prop: 'company',
                    width: 120
                }, {
                    type: 'input',
                    label: '物流单号',
                    prop: 'orderNum',
                    width: 120
                }, {
                    type: 'select',
                    label: '运单状态',
                    prop: 'status',
                    list: [],
                    width: 120
                }, {
                    type: 'daterange',
                    label: '创建日期',
                    prop: 'range',
                    width: 220
                }, {
                    type: 'submit',
                    label: '查询',
                }, {
                    type: 'reset',
                    label: '重置',
                }],
                searchValue: {},
                tableConfig: {
                    needIndex: true,
                    header: '运单列表',
                    headerButton: [{
                        name: '新增',
                        click: this.openForm,
                        hidden: true
                    }],
                    data: [],
                    column: [{
                        prop: "code",
                        label: "运单编号",
                        width: 100
                    }, {
                        prop: "userName",
                        label: "业务员",
                        align: 'center',
                    }, {
                        prop: "contact",
                        label: "收货人",
                        align: 'center',
                    }, {
                        prop: "createAt",
                        label: "创建日期",
                        align: 'center',
                        width: 150
                    }, {
                        prop: "note",
                        label: "备注",
                        align: 'center',
                    }, {
                        prop: "statusName",
                        label: "状态",
                        align: 'center',
                        width: "80",
                    }],
                    actionWidth: 90,
                    action: [{
                        name: '编辑',
                        click: (record) => this.openForm(record),
                        hidden: (record) => !this.isAdmin
                    }, {
                        name: '查看',
                        click: (record) => this.showForm(record)
                    }],
                    pageConfig: {
                        pageSize: this.$route.query.pageSize || 10,
                        pageNum: this.$route.query.pageNum || 1,
                        total: 0
                    },
                },
                rules: {
                    idUser: [
                        {required: true, message: '请选择业务员', trigger: 'blur'},
                    ]
                },
                isAdmin: false,
            }
        },
        mounted() {
            axiosUtils.get('isAdmin').then(res => {
                axiosUtils.get('getUserList').then(res => {
                    this.userList = res
                })
                axiosUtils.get('getStatusList').then(res => {
                    this.statusList = res
                    this.searchForm.find(item => item.prop === "status").list = res
                })
                this.isAdmin = res
                this.tableConfig.headerButton[0].hidden = !res
                if (res) {
                    this.tableConfig.column = [{
                        prop: "code",
                        label: "运单编号",
                        width: 100
                    }, {
                        prop: "userName",
                        label: "业务员",
                        align: 'center',
                    }, {
                        prop: "contact",
                        label: "收货人",
                        align: 'center',
                    }, {
                        prop: "company",
                        label: "物流公司",
                        align: 'center',
                    }, {
                        prop: "orderNum",
                        label: "物流单号",
                        align: 'center',
                    }, {
                        prop: "cost",
                        label: "费用",
                        align: 'center',
                    }, {
                        prop: "createAt",
                        label: "创建日期",
                        align: 'center',
                        width: 150
                    }, {
                        prop: "note",
                        label: "备注",
                        align: 'center',
                    }, {
                        prop: "statusName",
                        label: "状态",
                        align: 'center',
                        width: "80",
                    }]
                }
            })
            this.getData()
        },
        methods: {
            showModal(type) {
                if (this.taskDetails.status === 5) {

                } else {
                    let title = ''
                    if (type === 1) {
                        title = this.taskDetails.buttonName
                    } else if (type === 2) {
                        title = '关闭物流单'
                    }
                    this.$prompt(title, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({value}) => {
                        if (type === 1) {
                            axiosUtils.get('updateTaskStatus', {
                                id: this.taskDetails.id,
                                note: value
                            }).then(res => {
                                this.getTaskById(this.taskDetails.id)
                                this.getData()
                            })
                        } else if (type === 2) {
                            axiosUtils.get('cancelTask', {
                                id: this.taskDetails.id,
                                note: value
                            }).then(res => {
                                this.getTaskById(this.taskDetails.id)
                                this.getData()
                            })
                        }
                    }).catch(() => {

                    });
                }
            },
            showForm(task) {
                if (task) {
                    this.visibleDetails = true
                    this.getTaskById(task.id)
                }
            },
            getTaskById(id) {
                axiosUtils.get('getTaskDetails', {id}).then(res => {
                    this.taskDetails = res
                    this.getTaskMailHistory()
                })
            },
            getTaskMailHistory() {
                this.mailList = []
                axiosUtils.get('getTaskMailHistory', {
                    id: this.taskDetails.id
                }).then(res => {
                    this.mailList = res
                })
            },
            closeDetails() {
                this.visibleDetails = false
                this.taskDetails = {}
                this.mailList = []
            },
            openForm(task) {
                if (this.isAdmin) {
                    if (task && task.id) {
                        axiosUtils.get('getTaskDetails', {id: task.id}).then(res => {
                            this.form = res
                            this.temStatus = res.status + 0
                            this.fileList = res.photos.map(item => {
                                return {
                                    url: item
                                }
                            })
                            this.fileList2 = res.backPhotos.map(item => {
                                return {
                                    url: item
                                }
                            })
                        })
                    }
                    this.visible = true
                }
            },
            createForm: function (row) {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        axiosUtils.post('createTask', null, this.form).then(res => {
                            if (!this.form.id) {
                                this.$refs.uUpload.data.code = res.id
                                this.$refs.uUpload.submit();
                            } else {
                                this.getData()
                                this.closeDialog()
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            refresh() {
                this.tableConfig.pageConfig.pageNum = 1
                this.getData()
            },
            getData: function () {
                axiosUtils.get('getTaskList', {
                    ...this.searchValue,
                    pageSize: this.tableConfig.pageConfig.pageSize,
                    pageCurrent: this.tableConfig.pageConfig.pageNum
                }).then(res => {
                    this.tableConfig.data = res.content
                    this.tableConfig.pageConfig.total = res.totalElements
                })
            },
            closeDialog() {
                this.visible = false
                this.form = {
                    id: '',
                    idUser: '',
                    code: '',
                    contact: '',
                    company: '',
                    orderNum: '',
                    cost: '',
                    note: '',
                    status: 1,
                }
                this.fileList = []
                this.fileList2 = []
                this.$refs.ruleForm && this.$refs.ruleForm.clearValidate()
                this.$refs.uUpload && this.$refs.uUpload.clearFiles()
                this.$refs.uUpload2 && this.$refs.uUpload2.clearFiles()
            },
            onChange(file, fileList) {
                console.log(file, fileList)
                if (file.status === "ready") {
                    this.fileList.push(file)
                }
            },
            onChange2(file, fileList) {
                console.log(file, fileList)
                if (file.status === "ready") {
                    this.fileList2.push(file)
                }
            },
            onSuccess(response, file, fileList) {
                Message.closeAll()
                if (response.code === 200) {
                    Message.success(response.msg)
                    if (!this.form.id) {
                        this.closeDialog()
                    }
                    this.getData()
                } else {
                    Message.error(response.msg)
                }
            },
            onSuccess3(response, file, fileList) {
                Message.closeAll()
                if (response.code === 200) {
                    Message.success(response.msg)
                    axiosUtils.get('updateTaskStatus', {
                        id: this.taskDetails.id,
                        note: ''
                    }).then(res => {
                        this.getTaskById(this.taskDetails.id)
                        this.getData()
                    })
                } else {
                    Message.error(response.msg)
                }
            },
            onError(err, file, fileList) {
                Message.closeAll()
                console.log(err)
                Message.error(err)
            },
            onProgress() {
                Message.info({
                    message: '上传中...',
                    duration: 0
                })
            },
            handleRemove(file, type) {
                this.$confirm('您确定要删除该项么？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    if (file.raw) {
                        for (let i = 0; i < this['fileList' + type].length; i++) {
                            if (file.uid === this['fileList' + type][i].uid) {
                                this['fileList' + type].splice(i, 1)
                                break
                            }
                        }
                    } else {
                        axiosUtils.get('deletePhoto', {
                            url: file.url
                        }).then(res => {
                            for (let i = 0; i < this['fileList' + type].length; i++) {
                                if (file.uid === this['fileList' + type][i].uid) {
                                    this['fileList' + type].splice(i, 1)
                                    break
                                }
                            }
                        })
                    }
                });
            },
            statusChange(value) {

            },
            openViewer(photos) {
                this.showViewer = true
                this.photoUrls = photos
            },
            closeViewer() {
                this.photoUrls = []
                this.showViewer = false
            }
        }
    }
</script>

<style lang="scss">
    .upload-plus {
        display: flex;
        padding: 10px;
    }

    .el-upload--picture-card {
        width: 50px;
        height: 50px;
    }

    .image-class {
        width: 50px;
        height: 50px
    }

    .el-upload-list--picture-card .el-upload-list__item {
        width: 50px;
        height: 50px;
    }

    .delete-photo {
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 10px;
        right: 0;
        top: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        background-color: rgba(0, 0, 0, .5);
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }

    .el-dialog--center .el-dialog__body {
        padding: 10px 10px 0 10px;
    }
</style>